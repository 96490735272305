<template>
  <Modal ref="welcomeModal">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="flex justify-center w-full max-w-lg px-8 py-8 rounded-b-xl">
        <div class="flex flex-col w-full max-w-lg">
          <div class="w-full mb-2">
            <h1 class="text-xl font-bold">Welcome to Virtual Hallway! &#127881;</h1>
          </div>
          <div class="w-full max-w-lg px-4 pt-2 text-gray-600 text-md">
            <div class="px-2 text-sm text-left">
              We’re thrilled you’re here!
              <br />
              Welcome to your local community of clinicians.
              <br />
              <br />
              Let’s get started by giving you a quick site tour!
            </div>
            <div class="flex flex-col w-full py-4 pb-4 text-center md:flex-row">
              <div class="w-full px-2">
                <Button class="w-full get-started-button dark-mint-button-background" @click="startTour" id="welcomeModalStartTourButton" style="display: block"> Get Started </Button>
              </div>
            </div>
          </div>
          <button class="font-bold text-gray-400" @click="skipTour" id="skipSiteTourButton">Skip Site Tour</button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Button from 'primevue/button';
import Modal from '@/components/misc/Modal.vue';

export default {
  inject: ['mixpanel'],
  components: {
    Button,
    Modal,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'getUserActionByName']),
  },
  methods: {
    ...mapActions(['updateUserAction', 'setActiveTour']),
    ...mapMutations(['setActiveTour']),
    startTour() {
      let action = this.getUserActionByName(this.loggedInUser, 'main_tour');
      if (action) {
        this.updateUserTutorialByName(this.loggedInUser, 'main_tour', true, 'Completed');
        this.$refs.welcomeModal.closeModal();
        this.mixpanel.track('Started Site Tour');
        this.setActiveTour({ name: 'siteTour', status: 'active', expandSideBar: true });
        this.$nextTick(() => {
          this.$tours['siteTour'].start();
        });
      }
    },
    skipTour() {
      let action = this.getUserActionByName(this.loggedInUser, 'main_tour');
      if (action) {
        this.updateUserTutorialByName(this.loggedInUser, 'main_tour', true, 'Skipped');
        this.$refs.welcomeModal.closeModal();
      }
    },
  },
  watch: {
    'loggedInUser.actions'() {
      let action = this.getUserActionByName(this.loggedInUser, 'main_tour');
      if (action && action.status == 'Active') {
        this.$refs.welcomeModal.openModal();
      }
    },
  },
};
</script>

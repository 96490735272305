<template>
  <Dialog v-model:visible="visible" modal :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :dismissableMask="true" :draggable="false" id="accountSwitchedToOauthDialog">
    <template #header>Account Login Updated</template>
    <div class="flex flex-col justify-center items-center">
      <h1 class="my-3 font-semibold text-center md:text-lg">Your account has been successfully switched to {{ oauthProvider }} login.</h1>
      <p class="text-sm">Choose {{ oauthProvider }} login from now on to access your Virtual Hallway account!</p>
      <img src="https://app.virtualhallway.ca/storage/SSO_guide.gif" class="my-5 rounded-lg border" />
    </div>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['dialogVisible'],
  data() {
    return {
      visible: false,
      oauthProvider: null,
    };
  },
  computed: {
    ...mapGetters(['oauthUserConversionCode']),
  },
  created() {
    if (this.oauthUserConversionCode) {
      this.visible = this.dialogVisible;
      let metaData = JSON.parse(this.oauthUserConversionCode.meta_data);
      let oauth = metaData.requested_oauth_provider;
      this.oauthProvider = oauth.charAt(0).toUpperCase() + oauth.slice(1);
    }
  },
  components: {},
  methods: {
    ...mapActions(['getReferralsPagination']),
  },
  mounted() {},
  unmounted() {},
};
</script>

<style></style>

import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  referrals: [],
  allReferrals: [],
  sideBarReferrals: [],
  referral: null,
  selectedReferral: null,
  referralFeedback: null,
  referralToRebook: null,
  referralCounts: [],
  econsultCounts: [],
  referralComments: [],
  referralAddendums: [],
  savedReferralId: null,
  selectedInteractionType: 'phone',
  storedExternalReferralRequestCode: localStorage.getItem('externalReferralRequestCode'),
  externalReferralRequest: null,
  hasReferralDataWriteAccess: null,
  attachedReferrals: [],
  selectedEncounterNoteId: localStorage.getItem('selectedEncounterNoteId'),
};

const getters = {
  referrals: (state) => {
    return state.referrals;
  },
  allReferrals: (state) => {
    return state.allReferrals;
  },
  sideBarReferrals: (state) => {
    return state.sideBarReferrals;
  },
  referral: (state) => {
    return state.referral;
  },
  selectedReferral(state) {
    return state.selectedReferral;
  },
  referralFeedback: (state) => {
    return state.referralFeedback;
  },
  referralToRebook: (state) => {
    return state.referralToRebook;
  },
  referralCounts: (state) => {
    return state.referralCounts;
  },
  econsultCounts: (state) => {
    return state.econsultCounts;
  },
  referralComments: (state) => {
    return state.referralComments;
  },
  referralAddendums: (state) => {
    return state.referralAddendums;
  },
  savedReferralId: (state) => {
    return state.savedReferralId;
  },
  selectedInteractionType: (state) => {
    return state.selectedInteractionType;
  },
  storedExternalReferralRequestCode: (state) => {
    if (state.storedExternalReferralRequestCode) {
      return JSON.parse(state.storedExternalReferralRequestCode);
    } else return null;
  },
  externalReferralRequest: (state) => {
    return state.externalReferralRequest;
  },
  hasReferralDataWriteAccess: (state) => {
    return state.hasReferralDataWriteAccess;
  },
  attachedReferrals: (state) => {
    return state.attachedReferrals;
  },
  selectedEncounterNoteId: (state) => {
    return JSON.parse(state.selectedEncounterNoteId);
  },
};

// actions
const actions = {
  getReferralsPagination(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          response.data.data.data.forEach((referral) => {
            referral.className = referral.status.replace(/\s/g, ''); //Full Calendar
            referral.created_at = moment.utc(referral.created_at).toDate();
            referral.start_datetime = moment.utc(referral.start_datetime).toDate();
            referral.end_datetime = moment.utc(referral.end_datetime).toDate();
            referral.start = moment.utc(referral.start_datetime).toDate(); //Full Calendar
            if (referral.referral_response_form) {
              referral.referral_response_form.call_start_time = moment.utc(referral.referral_response_form.call_start_time).toDate();
              referral.referral_response_form.call_end_time = moment.utc(referral.referral_response_form.call_end_time).toDate();
            }
          });
          if (data.setSideBarReferrals) {
            context.commit(
              'setSideBarReferrals',
              response.data.data.data.filter((referral) => referral.interaction_type.name === 'phone')
            );
          }
          if (data.setReferrals === true) {
            context.commit('setAllReferrals', response.data.data.data);
            context.commit(
              'setReferrals',
              response.data.data.data.filter((referral) => referral.interaction_type.name === 'phone' && referral.call_asap === false)
            );
          } else if (data.setReferrals === false) {
            let referrals = context.state.referrals;
            let allReferrals = context.state.allReferrals;
            referrals = referrals.concat(response.data.data.data.filter((referral) => referral.interaction_type?.name === 'phone' && referral.call_asap === false));
            allReferrals = allReferrals.concat(response.data.data.data);
            context.commit('setAllReferrals', allReferrals);
            context.commit('setReferrals', referrals);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReferralById(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralById(data.id), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (data.setState) {
            context.commit('setReferral', response.data.data);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addReferral(context, referral) {
    if (context.state.referralToRebook !== null) {
      referral['rebooking_referral_id'] = context.state.referralToRebook.id;
      referral['cancellation_details'] = context.state.referralToRebook.cancellation_details;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.addReferralUrl(), referral, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.data.message == 'success') {
            context.commit('setReferral', response.data.data);
            context.commit('setReferralToRebook', null);
            context.dispatch('checkIfUserIsAllowedToMakeReferral', referral.referee_id);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateReferral(context, referral) {
    return new Promise((resolve, reject) => {
      axios
        .patch(globals.APIs.updateReferralUrl(referral.id), referral, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.data.message == 'success') context.commit('setReferral', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateResponseForm(context, responseForm) {
    return new Promise((resolve, reject) => {
      axios
        .patch(globals.APIs.updateReferralResponseFormUrl(responseForm.id), responseForm, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.data.message == 'success') {
            context.commit('setReferral', response.data.data);
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveResponseForm(context, responseForm) {
    return new Promise((resolve, reject) => {
      axios
        .patch(globals.APIs.saveReferralResponseFormUrl(responseForm.id), responseForm, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.data.message == 'success') {
            context.commit('setReferral', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancelReferral(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(globals.APIs.cancelReferralUrl(data.userId), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          context.dispatch('checkIfUserIsAllowedToMakeReferral', data.userId);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setReferralToRebook(context, referral) {
    return new Promise((resolve) => {
      context.commit('setReferralToRebook', referral);
      resolve('success');
    });
  },
  sendNudgeMessage(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.sendNudgeMessageUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendRunningLateMessage(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.sendRunningLateMessageUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReferralAddendumsByReferralId(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralAddendumsByReferralIdUrl(), {
          params: params,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferralAddendums', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createReferralAddendum(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createReferralAddendumUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          const addendums = context.rootGetters.referralAddendums;
          addendums.push(response.data.data);

          context.commit('setReferralAddendums', addendums);
          context.commit('setReferral', response.data.referral);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createReferralNotes(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createReferralNotesUrl(data.userId), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedReferral', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachOrgUnitToReferral(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getAttachOrgUnitToReferralUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          context.commit('setAllReferrals', response.data.referrals);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  detachOrgUnitToReferral(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getDetachOrgUnitToReferralUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          context.commit('setAllReferrals', response.data.referrals);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachOrgUnitToMultipleReferrals(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getAttachOrgUnitToMultipleReferralsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferrals', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  detachOrgUnitToMultipleReferrals(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getDetachOrgUnitToMultipleReferralsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          // context.commit('setReferral', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadReferralIcsFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.downloadReferralIcsFileUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let fileName = `consult_calender_${data.user_id}_${data.referral_id}.ics`;
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadPdfOfCombinedForm(context, referralId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadPdfOfCombinedFormUrl(referralId), {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = 'Consult Combined Report-' + referralId + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  bookDemoAsReferralPerson(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.bookDemoAsReferralPersonUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refereeHasSeenResponseForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.refereeHasSeenResponseFormUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clickedBookAnotherConsult(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.bookAnotherConsultUrl(data), null, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  storeExternalReferralRequestCode(context, data) {
    context.commit('storeExternalReferralRequestCode', data);
  },
  removeExternalReferralRequestCode(context) {
    context.commit('removeExternalReferralRequestCode');
  },
  getExternalReferralRequestByCode(context, requestCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getExternalReferralRequestByCodeUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: {
            request_code: requestCode,
          },
        })
        .then((response) => {
          let externalReferralRequest = response.data.external_referral_request;
          let referralData = {};
          for (let i = 0; i < externalReferralRequest.referral_data.length; i++) {
            let data = externalReferralRequest.referral_data[i];
            referralData[data.field_name] = data.field_value;
          }
          externalReferralRequest.referral_data = referralData;
          context.state.externalReferralRequest = response.data.external_referral_request;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportBillingCsv(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.exportBillingCsvUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = `billing_export.csv`;
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createOrUpdateThankYou(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createOrUpdateThankYouUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let referral = context.state.referral;
          referral.thank_you = response.data.data;
          context.commit('setReferral', referral);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  likeThankYou(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.likeThankYouUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let referral = context.state.referral;
          referral.thank_you = response.data.data;
          context.commit('setReferral', referral);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAttachedReferrals(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAttachedReferralsUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params,
        })
        .then((response) => {
          context.commit('setAttachedReferrals', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createOrUpdateEncounterNote(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createOrUpdateEncounterNoteUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferral', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  faxCombinedReferralForms(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.faxCombinedReferralFormsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setReferral(state, data) {
    data.start_datetime = moment.utc(data.start_datetime).toDate();
    data.start = moment.utc(data.start).toDate(); //Fullcalendar
    data.title = data.referral_person_last_name;
    data.end_datetime = moment.utc(data.end_datetime).toDate();
    data.created_at = moment.utc(data.created_at).toDate();
    data.referral_response_form.call_start_time = moment.utc(data.referral_response_form.call_start_time).toDate();
    data.referral_response_form.call_end_time = moment.utc(data.referral_response_form.call_end_time).toDate();
    state.referral = data;
  },
  setReferrals(state, data) {
    state.referrals = data;
  },
  setAllReferrals(state, data) {
    state.allReferrals = data;
  },
  setSideBarReferrals(state, data) {
    state.sideBarReferrals = data;
  },
  setAttachedReferrals(state, data) {
    data.forEach((referral) => {
      referral.start_datetime = moment.utc(referral.start_datetime).toDate();
      referral.end_datetime = moment.utc(referral.end_datetime).toDate();
    });
    state.attachedReferrals = data;
  },
  setSelectedReferral(state, data) {
    state.selectedReferral = data;
  },
  setReferralFeedback(state, data) {
    if (data != null) {
      data.created_at = moment.utc(data.created_at).toDate();
      data.updated_at = moment.utc(data.updated_at).toDate();
    }
    state.referralFeedback = data;
  },
  setReferralToRebook(state, data) {
    state.referralToRebook = data;
  },
  setReferralCounts(state, data) {
    state.referralCounts = data;
  },
  setEconsultCounts(state, data) {
    state.econsultCounts = data;
  },
  setReferralAddendums(state, data) {
    data.forEach((comment) => {
      comment.created_at = moment.utc(comment.created_at).toDate();
      comment.updated_at = moment.utc(comment.updated_at).toDate();
    });
    state.referralAddendums = data;
  },
  setReferralNotes(state, data) {
    state.referralNotess = data;
  },
  setSavedReferralId(state, data) {
    state.savedReferralId = data;
  },
  setSelectedInteractionType(state, data) {
    state.selectedInteractionType = data;
  },
  storeExternalReferralRequestCode(state, data) {
    state.storedExternalReferralRequestCode = JSON.stringify(data);
    localStorage.setItem('externalReferralRequestCode', JSON.stringify(data));
  },
  removeExternalReferralRequestCode(state) {
    state.storedExternalReferralRequestCode = null;
    state.externalReferralRequest = null;
    localStorage.removeItem('externalReferralRequestCode');
  },
  setHasReferralDataWriteAccess(state, data) {
    state.hasReferralDataWriteAccess = data;
  },
  setSelectedEncounterNoteId(state, data) {
    state.selectedEncounterNoteId = data;
    localStorage.setItem('selectedEncounterNoteId', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import { createStore } from 'vuex';

import auth from './modules/auth';
import user from './modules/user';
import referral from './modules/referral';
import referralForm from './modules/referral_form';
import specialty from './modules/specialty';
import lectures from './modules/lectures';
import schedule from './modules/schedule';
import province from './modules/province';
import onboarding from './modules/onboarding';
import referralResponseForm from './modules/referral_response_form';
import orgUnit from './modules/org_unit';
import typeForm from './modules/type_form';
import smart_path from './modules/smart_path';
import zones from './modules/zones';
import referral_response_form_related_file from './modules/referral_response_form_related_file';
import azure_communication from './modules/azure_communication';
import scribe from './modules/scribe';
import interaction_type from './modules/interaction_type';
import app from './modules/app';
import booking_hallway from './modules/booking_hallway';
import attached_file from './modules/attached_file';
import poems from './modules/poems';
import cme from './modules/cme';
import user_analytic from './modules/user_analytic';
import connect_program from './modules/connect_program';
import tour from './modules/tour';

export default createStore({
  modules: {
    auth,
    user,
    referral,
    referralForm,
    specialty,
    lectures,
    schedule,
    province,
    onboarding,
    referralResponseForm,
    orgUnit,
    typeForm,
    smart_path,
    zones,
    referral_response_form_related_file,
    azure_communication,
    scribe,
    interaction_type,
    booking_hallway,
    attached_file,
    poems,
    cme,
    user_analytic,
    connect_program,
    tour,
    app,
  },
});

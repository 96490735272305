<template>
  <div v-if="filteredUpcomingLectures.length > 0">
    <div class="flex flex-col-reverse justify-between mb-8 md:flex-row">
      <div class="flex flex-col items-start w-full text-left">
        <h1 class="mb-6 text-2xl font-bold">Upcoming Lectures</h1>
        <p class="mb-4 -mt-4 text-sm">Select a lecture below to learn more, or register to attend the live event.</p>
        <div class="c-divider" style="margin-left: 0"></div>
      </div>
    </div>
    <div v-auto-animate class="grid grid-cols-1 mb-16 lecture-grid justify-items-center md:grid-cols-2" :class="[{ 'lg:grid-cols-2 gap-12': upcomingLectures.length < 3 }, { 'lg:grid-cols-3 gap-6': upcomingLectures.length >= 3 }]">
      <LectureCard v-for="(lecture, index) in filteredUpcomingLectures" :key="lecture.id" :lecture="lecture" :index="index" buttonText="Register" />
      <div v-if="!isLoading && loggedInUser && filteredUpcomingLectures.length < 1" class="relative flex flex-col items-start justify-start w-full border-1">
        <h1 class="mb-2 text-2xl">No Results</h1>
        <Button label="Clear Filters" @click="clearFilters()" class="p-button-xs" style="width: 200px" />
      </div>
    </div>
  </div>

  <div class="flex flex-col justify-start w-full text-left sm:flex-row sm:justify-between" ref="onDemandLectures">
    <div class="flex-col justify-start flex-">
      <h1 class="mb-6 text-2xl font-bold">Watch on demand</h1>
      <p class="mb-4 -mt-4 text-sm">Select a lecture below to watch the recording, or submit your feedback to receive your free CME credit documentation.</p>
      <div class="mb-8 c-divider" style="margin-left: 0"></div>
    </div>
  </div>

  <div v-auto-animate class="grid grid-cols-1 gap-6 mb-16 lecture-grid justify-items-center md:grid-cols-2 lg:grid-cols-3">
    <LectureCardLoadingSkeleton v-if="isLoading" />
    <LectureCardLoadingSkeleton v-if="isLoading" />
    <LectureCardLoadingSkeleton v-if="isLoading" />
    <LectureCard v-for="(lecture, index) in filteredPastLectures" :key="lecture.id" :lecture="lecture" :index="index" buttonText="Watch" />
    <div v-if="!isLoading && loggedInUser && filteredPastLectures.length < 1" @click="clearFilters()" class="relative flex flex-col items-center justify-center w-full h-64 cursor-pointer rounded-xl border-1">
      <h1 class="mb-2 text-2xl">No Results</h1>
      <Button label="Clear Filters" class="p-button-xs" style="width: 200px" />
    </div>
  </div>
</template>

<script>
import LectureCard from '@/components/cme/LectureCard.vue';
import LectureCardLoadingSkeleton from '@/components/cme/LectureCardLoadingSkeleton.vue';

export default {
  props: ['upcomingLectures', 'filteredUpcomingLectures', 'filteredPastLectures'],
  emits: ['clearFilters'],
  components: {
    LectureCard,
    LectureCardLoadingSkeleton,
  },
  clearFilters() {
    this.$emits('clearFilters');
  },
};
</script>

import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  selectedPoem: null,
  selectedPoemComments: [],
  poemsPaginator: null,
  poems: [],
  poemsScrollPosition: null,
  poemTags: [],
};

const getters = {
  poemsPaginator: (state) => {
    return state.poemsPaginator;
  },
  poemsScrollPosition: (state) => {
    return state.poemsScrollPosition;
  },
  poems: (state) => {
    return state.poems;
  },
  poemTags: (state) => {
    return state.poemTags;
  },
  selectedPoem: (state) => {
    return state.selectedPoem;
  },
  selectedPoemComments: (state) => {
    return state.selectedPoemComments;
  },
};

const actions = {
  getPoem(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getPoemUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedPoem', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPoems(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          // Save the request filters, sort, and url on the paginator to use to reset poems page if refresh
          response.data.data.filters = data.filters;
          response.data.data.sort = data.sort;
          response.data.data.url = data.url;
          context.commit('setPoemsPaginator', response.data.data);
          context.commit('setPoems', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPoemTags(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getPoemTagsUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setPoemTags', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cratePoemComment(context, data) {
    const originalData = JSON.parse(JSON.stringify(data));
    data.poem.comments_count++;
    context.commit('updatePoemInPoems', data.poem);
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.poemCommentUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (!data.poem_comment_discussion_id) {
            let selectedPoemComments = context.getters.selectedPoemComments;
            selectedPoemComments.push(response.data.data);
            context.commit('setSelectedPoemComments', selectedPoemComments);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          context.commit('updatePoemInPoems', originalData);
          reject(error);
        });
    });
  },
  getPoemComments(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.poemCommentUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (!data.poem_comment_id) {
            context.commit('setSelectedPoemComments', response.data.data);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePoemComment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(globals.APIs.poemCommentUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let selectedPoemComments = context.getters.selectedPoemComments;
          if (!data.comment.poem_comment_discussion_id) {
            if (data.comment.comments.length === 0) {
              selectedPoemComments = selectedPoemComments.filter((comment) => comment.id !== data.comment.id);
            } else {
              let selectedPoemComment = selectedPoemComments.find((comment) => comment.id === data.comment.id);
              selectedPoemComment.user = null;
              selectedPoemComment.text = 'User Deleted Comment';
            }
          } else {
            let discussionComment = selectedPoemComments.find((comment) => comment.id === data.comment.poem_comment_discussion_id);
            discussionComment.comments = discussionComment.comments.filter((comment) => comment.id !== data.comment.id);
          }

          context.commit('setSelectedPoemComments', selectedPoemComments);
          resolve(response.data.data);
        })
        .catch(() => {
          reject(error);
        });
    });
  },
  reportPoemComment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.reportPoemCommentUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  togglePoemBookmark(context, data) {
    const originalData = JSON.parse(JSON.stringify(data));
    if (data.bookmarks.length > 0) {
      data.bookmarks = [];
    } else {
      data.bookmarks.push(context.rootGetters.loggedInUser);
    }
    context.commit('updatePoemInPoems', data);
    context.commit('setSelectedPoem', data);
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.togglePoemBookmarkUrl(),
          { poem_id: data.id },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          context.commit('updatePoemInPoems', originalData);
          context.commit('setSelectedPoem', originalData);
          reject(error);
        });
    });
  },
  togglePoemLike(context, data) {
    const originalData = JSON.parse(JSON.stringify(data));
    if (data.likes.length > 0) {
      data.likes = [];
      data.likes_count--;
    } else {
      data.likes.push(context.rootGetters.loggedInUser);
      data.likes_count++;
    }
    context.commit('updatePoemInPoems', data);
    context.commit('setSelectedPoem', data);
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.togglePoemLikeUrl(),
          { poem_id: data.id },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          context.commit('setSelectedPoem', originalData);
          context.commit('updatePoemInPoems', originalData);
          reject(error);
        });
    });
  },
  subscribeToKlaviyoList(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.subscribeToKlaviyoListUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let user = context.rootGetters.loggedInUser;
          if (user) {
            let notificationSetting = user.profile.notification_setting;
            notificationSetting.poems = ['Email'];
            user.profile.notification_setting = notificationSetting;
            context.commit('updateLoggedInUser', user);
          }
          resolve(response.data.data);
        })
        .catch(() => {
          reject(error);
        });
    });
  },
};

const mutations = {
  // Purge all poems state
  purgePoems: (state) => {
    state.poemsPaginator = null;
    state.poems = [];
    state.poemsScrollPosition = null;
    state.poemTags = [];
  },
  setPoemsPaginator(state, data) {
    state.poemsPaginator = data;
  },
  setPoemsScrollPosition(state, data) {
    state.poemsScrollPosition = data;
  },
  setPoemTags(state, data) {
    data.sort((a, b) => a.name.localeCompare(b.name));
    state.poemTags = data;
  },
  updatePoemInPoems(state, data) {
    let poems = state.poems;
    poems = poems.map((poem) => {
      if (poem.id === data.id) {
        return { ...poem, ...data };
      }
      return poem;
    });

    state.poems = poems;
  },
  setPoems(state, data) {
    // If current page is 1 reset all local store poems
    if (data.current_page === 1) {
      state.poems = data.data;
    }
    // Else append these poems on top poems already in local store / state
    else {
      let poems = state.poems;
      poems = poems.concat(data.data);
      state.poems = poems;
    }
  },
  setSelectedPoem(state, data) {
    state.selectedPoem = data;
  },
  setSelectedPoemComments(state, data) {
    state.selectedPoemComments = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

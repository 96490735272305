<template>
  <div class="justify-start gap-1.5 sm:gap-2.5 flex mb-2 w-full">
    <div class="relative min-w-10">
      <div class="w-10 h-10 bg-gray-300 rounded-full justify-start items-start gap-2.5 flex min-w-10 z-10">
        <img class="object-cover w-10 h-10 rounded-full" v-if="comment?.user?.profile" v-bind:src="comment.user.profile.profile_picture_location" />
        <img class="object-cover w-10 h-10 rounded-full" v-else src="https://app.virtualhallway.ca/storage/user_profile_pictures/default.png" />
      </div>
      <div class="absolute bottom-0 z-0 flex items-center justify-center w-8 mb-3 cursor-pointer top-12 left-1 group" aria-hidden="true" v-if="comment.comments.length > 0">
        <div data-testid="main-thread-line" class="w-[1px] h-full group-hover:bg-gray-500 bg-gray-300"></div>
      </div>
    </div>

    <div class="w-full pl-1">
      <div class="flex-col items-start justify-start gap-1">
        <h5 class="text-sm font-semibold leading-snug text-gray-900" v-if="comment.user">{{ comment.user.first_name }} {{ comment.user.last_name }}</h5>
        <h5 class="text-sm font-semibold leading-snug text-gray-900" v-else>Deleted</h5>
        <h6 class="text-xs font-normal leading-5 text-gray-500">{{ formatDateTimeToSpecifiedFormat(comment.created_at, 'MMM D, YYYY • h:mma', true) }}</h6>
      </div>
      <div class="mt-3 leading-snug text-gray-800 break-words overflow-hidden max-w-72 md:max-w-full text-sm"> {{ comment.text }} </div>
      <div class="flex flex-row" v-if="comment.user">
        <div class="flex items-center justify-start px-3 py-1 mt-2 cursor-pointer flew-row max-w-20 hover:bg-gray-100 hover:border-gray-400 rounded-xl" v-on:click.stop @click="showReplyInputOnClick" id="showReplyToCommentButton">
          <i class="pr-2 pi pi-reply" style="font-size: 0.9rem"></i>
          <span class="text-xs font-base">Reply</span>
        </div>
        <div class="flex items-center justify-center px-3 py-1 mt-2 cursor-pointer flew-row max-w-20 hover:bg-gray-100 hover:border-gray-400 rounded-xl" v-on:click.stop @click="showMenuOnClick" id="poemCommentMenuButton">
          <i class="pi pi-ellipsis-h" style="font-size: 0.9rem"></i>
        </div>
      </div>

      <div class="w-full pt-4" v-if="showReplyInput">
        <Textarea v-model="replyComment" :autoResize="true" rows="2" cols="30" required maxLength="65535" id="replyCommentTextarea" />
        <div class="flex justify-end mt-2">
          <Button label="Comment" icon="pi pi-comment" class="p-button-secondary p-button-outlined p-button-sm" @click="submitComment" :loading="isLoadingComment" :disabled="isLoadingComment" id="submitReplyCommentButton" />
        </div>
      </div>
      <div v-if="comment?.comments">
        <div v-for="commentReply in comment.comments.filter((comment) => comment.user)" :key="commentReply.id" class="pt-3 mt-3 break-words">
          <PoemComment :comment="commentReply" :poem="poem" :depth="depth + 1" :discussionComment="discussionComment" />
        </div>
      </div>
    </div>
  </div>
  <Menu ref="menu" style="width: 210px" :model="menuItems" :popup="true"> </Menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  inject: ['mixpanel'],
  props: ['comment', 'poem', 'depth', 'discussionComment'],
  components: {},
  data() {
    return {
      showReplyInput: false,
      replyComment: null,
      isLoadingComment: false,
      isLoadingComments: false,
      menuItems: [],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['cratePoemComment', 'deletePoemComment', 'reportPoemComment']),
    showReplyInputOnClick() {
      this.showReplyInput = !this.showReplyInput;
      this.replyComment = null;
    },
    submitComment() {
      if (!this.replyComment) {
        return;
      }

      this.isLoadingComment = true;
      this.cratePoemComment({
        poem: this.poem,
        poem_id: this.poem.id,
        text: this.replyComment,
        poem_comment_discussion_id: this.discussionComment.id,
        poem_comment_parent_id: this.comment.id,
      })
        .then((data) => {
          this.discussionComment.comments.push(data);

          this.replyComment = null;
          this.isLoadingComment = false;
          this.showReplyInput = false;
        })
        .catch(() => {
          this.isLoadingComment = false;
          this.showGenericErrorToast();
        });
    },
    showMenuOnClick(event) {
      this.menuItems = [];
      this.menuItems.push({
        label: 'Report',
        icon: 'pi pi-flag',
        command: () => {
          this.reportPoemComment({ poem_comment_id: this.comment.id })
            .then(() => {
              this.$toast.removeAllGroups();
              this.$toast.add({
                severity: 'success',
                summary: 'Comment Reported',
                detail: 'Comment has successfully been reported.',
                life: 3000,
              });
            })
            .catch(() => {
              this.showGenericErrorToast();
            });
        },
      });

      if (this.comment.user.id === this.loggedInUser.id) {
        this.menuItems.push({
          label: 'Delete',
          icon: 'pi pi-trash',
          command: () => {
            this.deletePoemComment({ poem_comment_id: this.comment.id, comment: this.comment })
              .then(() => {
                this.$toast.removeAllGroups();
                this.$toast.add({
                  severity: 'success',
                  summary: 'Comment Deleted',
                  detail: 'Your comment has successfully been deleted.',
                  life: 3000,
                });
              })
              .catch(() => {
                this.showGenericErrorToast();
              });
          },
        });
      }

      this.$refs.menu.toggle(event);
    },
  },
  created() {},
};
</script>

<style></style>

<template>
  <div :class="animation" class="w-full max-w-lg p-8 tile" v-if="!isLoading">
    <div class="flex flex-col w-full text-start">
      <div class="flex flex-col w-full mb-5 text-start">
        <h1 class="text-xl font-bold text-left" id="professionHeader">What is your profession?</h1>
        <span class="text-sm text-red-500" v-if="errors.profession">{{ errors.profession }}</span>
      </div>
      <div class="mb-4 grid col-auto md:grid-cols-2 gap-3" id="selectProfessionDiv">
        <div v-for="(profession, index) in allProfessions" :key="index" @click="user.profession = profession" class="select-button-small border-hover--blue" :class="profession == user.profession ? '!border-blue-500' : 'bg-primary'" id="" :aria-label="profession">
          <span>{{ profession }}</span>
        </div>
      </div>
      <div class="my-3 col-span-2" v-if="user.profession === 'Other'">
        <div class="text-left p-float-label">
          <InputText type="text" name="other_profession" id="other_profession" v-model="otherProfession" class="w-full p-inputtext-md" maxLength="55" required />
          <label for="other_profession">Enter Your Profession</label>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full mb-5 text-start">
      <h1 class="mt-8 text-xl font-bold text-left">What is your province/state?</h1>
      <span class="text-sm text-red-500" v-if="errors.province">{{ errors.province }}</span>
    </div>
    <div class="mb-8">
      <div class="px-0 text-left">
        <label for="selectCountryDropDown" class="auth-input">Country</label>
        <Dropdown class="w-full p-inputtext-md" v-model="selectedCountry" :options="countryOptions" id="selectCountryDropDown" :filter="false" optionLabel="option" />
      </div>
    </div>
    <div class="mb-8">
      <div class="px-0 text-left">
        <label for="selectProvinceDropDown" class="auth-input">{{ selectedCountry.value === 'CA' ? 'Province' : 'State' }}</label>
        <Dropdown class="w-full p-inputtext-md" v-model="selectedProvince" :options="provinceOptions" optionLabel="name" id="selectProvinceDropDown" :filter="false" @change="setProvince" :placeholder="selectedCountry.value === 'CA' ? 'Select Province' : 'Select State'" />
      </div>
    </div>
    <div class="flex flex-row items-center justify-between mt-8">
      <Button label="Previous" @click="goToPrevious()" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
      <div v-tooltip="{ value: 'Please Select Profession and Province/State', disabled: user.profession && selectedProvince }">
        <Button label="Complete" class="p-button p-buttom-sm" iconPos="right" icon="pi pi-check" @click="goToNext()" :disabled="!user.profession || !selectedProvince" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';
import { mapActions } from 'vuex/dist/vuex.cjs.js';

export default {
  components: {
    Button,
  },
  props: ['loggedInUser'],
  emits: ['goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      firstName: null,
      lastName: null,
      user: null,
      errors: {},
      isLoading: true,
      allProfessions: ['Physician', 'Nurse Practitioner', 'Pharmacist', 'Other'],
      otherProvince: null,
      otherProfession: null,
      countryOptions: [
        { option: 'Canada', value: 'CA' },
        { option: 'United States', value: 'US' },
      ],
      selectedCountry: { option: 'Canada', value: 'CA' },
      selectedProvince: null,
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'allProvinces']),
    provinceOptions() {
      return this.allProvinces.filter((province) => province.country === this.selectedCountry.value);
    },
  },
  created() {
    this.isLoading = true;
    this.user = this.loggedInUser;
    this.getAllProvinces()
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {});
  },
  methods: {
    ...mapActions(['getAllProvinces']),
    goToNext() {
      this.errors = {};
      if (!this.user.profession || this.user.profession == 'cme' || (this.user.profession == 'Other' && !this.otherProfession)) {
        this.errors.profession = 'Please provide your profession.';
      } else if (this.user.profession == 'Other') {
        this.user.profession = this.otherProfession;
      }
      if (!this.user.address.province || (this.user.address.province == 'Other' && !this.otherProvince)) {
        this.errors.province = 'Please provide your province or state.';
      } else if (this.user.address.province == 'Other') {
        this.user.address.province = this.otherProvince;
      }
      if (Object.keys(this.errors).length < 1) {
        this.$store.commit('updateLoggedInUser', this.user);
        this.$emit('goToNext', this.user);
      } else {
        document.getElementById('professionHeader').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    },
    setProvince() {
      this.user.address.province = this.selectedProvince.name;
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
  },
};
</script>

<style scoped>
.p-float-label label {
  color: rgb(152, 152, 152) !important;
}
</style>

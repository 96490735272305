import axios from 'axios';
import globals from '../../globals';

const state = {};

const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <Dialog :draggable="false" :dismissable-mask="true" v-model:visible="displayDialog" :modal="true" :blockScroll="true" id="claim-credit-dialog" class="w-full md:w-1/2 max-w-2xl m-2">
    <template #header>
      <div class="flex items-center justify-between w-full flew-row">
        <div class="w-full p-dialog-title">{{ selectedLanguage == 'En' ? 'Claim your CME Credit' : 'Réclamez votre crédit CME' }}</div>
        <div class="w-[150px] pr-4"><SelectButton v-model="selectedLanguage" :options="['En', 'Fr']" aria-labelledby="basic" @change="onSelectedLanguageChange" /></div>
      </div>
    </template>
    <div class="w-full overflow-hidden min-w-3xl">
      <form v-show="lectureCreditFormSubmitted != true" @submit.prevent="submit" class="w-full border-2 border-white border-solid rounded-lg" v-auto-animate>
        <div class="flex flex-col" :key="questionIndex" v-auto-animate>
          <template v-if="selectedQuestion.type === 'multiselect'">
            <div class="animated fade-in-left animated-delay-3">
              <span class="flex flex-row mb-4 font-medium text-gray-600 font-display"> {{ selectedQuestion.question }} </span>
              <span v-if="errors.question" class="mb-2 text-red-500">{{ errors.question }}</span>
              <div class="flex flex-col">
                <div v-for="(option, idx) in selectedQuestion.options" :key="idx" class="flex flex-col my-2">
                  <div class="flex">
                    <Checkbox v-model="option.value" :inputId="`multiselect-option-${idx}`" :binary="true" @change="subSubQuestionChanged(option)" />
                    <label :for="`multiselect-option-${idx}`" class="ml-2 text-sm cursor-pointer">{{ option.question }}</label>
                  </div>
                  <div v-if="option.value && option.subQuestions" class="flex flex-col w-full ml-12">
                    <div class="flex flex-row mt-2 mb-2 text-sm">- {{ option.subQuestions.question }}</div>
                    <div v-if="errors.subQuestion" class="mb-2 text-red-500">{{ errors.subQuestion }}</div>
                    <div class="flex flex-col">
                      <div v-for="(opt, idx) in option.subQuestions.options" :key="idx" class="flex flex-row my-2">
                        <template v-if="!opt.hidden">
                          <div class="flex">
                            <Checkbox v-model="opt.value" :inputId="idx" :binary="true" />
                          </div>
                          <label :for="idx" class="ml-2 text-sm cursor-pointer"> {{ opt.question }}</label>
                        </template>
                      </div>
                    </div>
                    <div v-if="errors.otherTextValue" class="mt-2 text-red-500 -pb-2">{{ errors.otherTextValue }}</div>
                    <div v-if="option.subQuestions.options?.length === 0 || option.subQuestions?.options?.find((option) => ['Other', 'Autre'].includes(option.question))?.value">
                      <Textarea v-model="option.subQuestions.otherTextValue" class="!w-3/4" :autoResize="true" rows="5" required maxLength="65535" :placeholder="selectedLanguage === 'En' ? 'Enter description here' : 'Veuillez décrire ce problème'" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="selectedQuestion.type === 'single'">
            <div v-auto-animate class="animated fade-in-left animated-delay-3">
              <div class="flex flex-row mb-4 font-medium text-gray-600 font-display">{{ selectedQuestion.question }}</div>
              <div v-if="errors.question" class="text-[#dc3545] mb-2">{{ errors.question }}</div>
              <div class="grid items-start grid-cols-2 gap-4">
                <div v-for="(option, idx) in selectedQuestion.options" :key="idx" class="flex flex-row">
                  <div class="flex">
                    <Checkbox v-model="option.value" :inputId="option.question.replace(/\s/g, '')" :binary="true" @change="yesNoOnClick(option)" />
                  </div>
                  <label :for="option.question.replace(/\s/g, '')" class="ml-2 text-sm cursor-pointer">{{ option.question }}</label>
                </div>
              </div>
              <template v-if="selectedSubQuestion">
                <template v-if="selectedLanguage === 'En'">
                  <div class="flex flex-row mt-4 mb-2" v-if="selectedSubQuestion?.options?.length !== 0">Please check all that apply</div>
                  <div class="flex flex-row mt-4 mb-2" v-else>Please describe how this information may be harmful</div>
                </template>
                <template v-else>
                  <div class="flex flex-row mt-4 mb-2" v-if="selectedSubQuestion?.options?.length !== 0">Veuillez sélectionner tout ce qui s'applique</div>
                  <div class="flex flex-row mt-4 mb-2" v-else>Veuillez décrire en quoi ces informations peuvent être nuisibles</div>
                </template>
                <div v-if="errors.subQuestion" class="text-[#dc3545] mb-2">{{ errors.subQuestion }}</div>
                <div class="flex flex-col">
                  <div v-for="(option, idx) in selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedQuestion.options.find((option) => option.value)?.question).options" :key="idx" class="flex flex-row my-2">
                    <div class="flex">
                      <Checkbox v-model="option.value" :inputId="idx" :binary="true" />
                    </div>
                    <label :for="idx" class="ml-2 text-sm cursor-pointer"> {{ option.question }}</label>
                  </div>
                </div>
                <div v-if="errors.otherTextValue" class="text-[#dc3545] mt-2 -pb-2">{{ errors.otherTextValue }}</div>
                <div v-if="selectedSubQuestion?.options?.length === 0 || selectedSubQuestion?.options.find((option) => ['Other', 'Autre'].includes(option.question))?.value">
                  <div :class="selectedSubQuestion?.options?.length === 0 ? '' : 'mt-4'">
                    <Textarea v-model="selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedQuestion.options.find((option) => option.value)?.question).otherTextValue" class="" :autoResize="true" rows="5" cols="30" required maxLength="65535" :placeholder="selectedLanguage === 'En' ? 'Please describe this problem' : 'Veuillez décrire ce problème'" />
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-else-if="selectedQuestion.type === 'final'">
            <div class="animated fade-in-left animated-delay-3">
              <div>
                <div v-for="(question, idx) in selectedQuestion.questions" :key="idx" class="flex flex-col">
                  <div class="flex flex-row mb-4 font-medium text-gray-600 font-display">{{ question.question }}</div>
                  <div v-if="errors[`question-${idx}`]" class="text-[#dc3545] mb-2">{{ errors[`question-${idx}`] }}</div>
                  <div class="grid items-start grid-cols-2 gap-4">
                    <div v-for="option in question.options" :key="option" class="flex flex-row mb-4">
                      <div class="flex">
                        <Checkbox v-model="option.value" :inputId="`${option.question}-${idx}`" :binary="true" @change="yesNoFinalOnClick(question, option)" />
                      </div>
                      <label :for="`${option.question}-${idx}`" class="ml-2 text-sm cursor-pointer">{{ option.question }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="flex flex-row w-full mb-1 font-semibold text-primary">{{ selectedQuestion.otherTextPlaceholder }}</div>
                <Textarea v-model="selectedQuestion.otherTextValue" class="" :autoResize="true" rows="5" cols="30" maxLength="65535" />
              </div>
            </div>
          </template>
        </div>
      </form>
    </div>
    <div v-auto-animate v-show="lectureCreditFormSubmitted == true" class="flex flex-col items-center justify-center px-4 md:px-16 py-16 md:py-24 border-2 border-gray-200 border-solid rounded-lg">
      <i class="mt-2 mb-6 fas fa-award text-blue" style="font-size: 2.5rem"></i>
      <h2 class="mb-6 text-2xl font-bold text-center sm:text-left text-blue">{{ selectedLanguage === 'En' ? 'CME Credit Claimed' : 'Crédit CME réclamé' }}</h2>
      <p class="mb-8 text-sm text-center">{{ selectedLanguage === 'En' ? "All yours credits can be viewed in the My Credits page. Don't forget to transfer your credits to your Mainpro+ account"  : "Tous vos crédits peuvent être consultés dans Mes crédits. N'oubliez pas de transférer vos crédits sur votre compte Mainpro+" }}</p>
      <div class="flex flex-row gap-x-3">
        <Button :label="selectedLanguage === 'En' ? 'My Credits' : 'Mes Crédits'" class="p-button-secondary" @click="$router.push('/poems-cme-credits')" />
        <Button :label="selectedLanguage === 'En' ? 'More POEMs' : 'Plus POEMs'" class="p-button-primary" @click="$router.push('/poems')" />
      </div>
    </div>
    <template #footer v-if="!lectureCreditFormSubmitted">
      <div class="flex flex-row w-full" :class="[questionIndex === 0 ? '!justify-end' : '', selectedQuestion.type !== 'single' || selectedSubQuestion || selectedQuestion?.options?.find((option) => option.value) ? 'justify-between' : 'justify-start']">
        <Button label="Back" icon="pi pi-arrow-left" class="p-button p-button-secondary p-button-outlined" @click="goBack" v-if="questionIndex !== 0" />
        <Button :label="questionIndex !== questions.length - 1 ? 'Next' : 'Submit'" iconPos="right" icon="pi pi-arrow-right" class="p-button p-button-secondary" @click="goToNext" v-if="selectedQuestion.type !== 'single' || selectedSubQuestion || selectedQuestion?.options?.find((option) => option.value)" :disabled="isLoading" :loading="isLoading" id="claimCmeCreditNextButton" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import TextArea from 'primevue/textarea';
import Rating from 'primevue/rating';
import Message from 'primevue/message';
import SelectButton from 'primevue/selectbutton';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  inject: ['mixpanel'],
  components: { Button, Dialog, TextArea, Rating, Message, SelectButton },
  data() {
    return {
      selectedLanguage: 'En',
      errors: {},
      lectureCreditFormSubmitted: false,
      isLoading: false,
      displayDialog: false,
      answers: [],
      questionIndex: 0,
      questionIndexProcessStack: [],
      questions: {
        En: [
          {
            question: 'What is the impact of this information on you or your practice? Please check all that apply',
            options: [
              { question: 'I learned something new', value: false },
              { question: 'I am motivated to learn more', value: false },
              { question: 'This information confirmed I did (am doing) the right thing', value: false },
              { question: 'I am reassured', value: false },
              { question: 'I am reminded of something I already knew', value: false },
              { question: 'I am dissatisfied', value: false },
              {
                question: 'There is a problem with the presentation of this information',
                value: false,
                subQuestions: {
                  question: 'What problem do you see?',
                  options: [
                    { question: 'Too much information', value: false },
                    { question: 'Not enough information', value: false },
                    { question: 'Information poorly written', value: false },
                    { question: 'Too technical', value: false },
                    { question: 'Other', value: false },
                  ],
                  otherTextValue: null,
                },
              },
              { question: 'I disagree with the content of this information', value: false },
              {
                question: 'This information is potentially harmful',
                value: false,
                subQuestions: {
                  question: 'Please describe how this information may be harmful',
                  options: [{ question: 'Other', value: true, hidden: true }],
                  otherTextValue: null,
                },
              },
            ],
            type: 'multiselect',
          },
          // {
          //   //Put in first modal
          //   question: 'Was there a problem with the presentation of this information?',
          //   type: 'single',
          //   options: [
          //     { question: 'Yes', value: false },
          //     { question: 'No', value: false },
          //   ],
          //   subQuestions: [
          //     {
          //       question: 'Yes',
          //       options: [
          //         { question: 'Too much information', value: false },
          //         { question: 'Not enough information', value: false },
          //         { question: 'Information poorly written', value: false },
          //         { question: 'Too technical', value: false },
          //         { question: 'Other', value: false },
          //       ],
          //       otherTextValue: null,
          //     },
          //   ],
          // },
          {
            question: 'Is this information relevant for at least one of your patients?',
            type: 'single',
            options: [
              { question: 'Totally relevant', value: false },
              { question: 'Partially relevant', value: false },
              { question: 'Not relevant', value: false },
            ],
            nextQuestion: [
              {
                answer: 'Totally relevant',
                next: 'Will you use this information for a specific patient?',
              },
              {
                answer: 'Partially relevant',
                next: 'Will you use this information for a specific patient?',
              },
              {
                answer: 'Not relevant',
                next: 'Final Question',
              },
            ],
          },
          {
            question: 'Will you use this information for a specific patient?',
            type: 'single',
            options: [
              { question: 'Yes', value: false },
              { question: 'Possibly', value: false },
              { question: 'No', value: false },
            ],
            subQuestions: [
              {
                question: 'Yes',
                options: [
                  { question: 'As a result of this information I will manage this patient differently', value: false },
                  { question: 'I had several options for this patient, and I will use this information to justify a choice', value: false },
                  { question: 'I thought I knew what to do, and I used this information to be more certain about the management of this patient', value: false },
                  { question: 'I used this information to better understand a particular issue related to this patient', value: false },
                  { question: 'I will use this information in a discussion with this patient, or with other health professionals about this patient', value: false },
                  { question: 'I will use this information to persuade this patient, or to persuade other health professionals to make a change for this patient', value: false },
                ],
                otherTextValue: null,
              },
            ],
            nextQuestion: [
              {
                answer: 'Yes',
                next: 'For this patient, do you expect any health benefits as a result of applying this information?',
              },
              {
                answer: 'Possibly',
                next: 'Final Question',
              },
              {
                answer: 'No',
                next: 'Final Question',
              },
            ],
          },
          {
            question: 'For this patient, do you expect any health benefits as a result of applying this information?',
            type: 'single',
            options: [
              { question: 'Yes', value: false },
              { question: 'No', value: false },
            ],
            subQuestions: [
              {
                question: 'Yes',
                options: [
                  { question: 'This information will help to improve this patient‟s health status, functioning or resilience (i.e., ability to adapt to significant life stressors)', value: false },
                  { question: 'This information will help to prevent a disease or worsening of disease for this patient', value: false },
                  { question: 'This information will help to avoid unnecessary or inappropriate treatment, diagnostic procedures, preventative interventions or a referral, for this patient', value: false },
                ],
                otherTextValue: null,
              },
            ],
          },
          {
            question: 'Final Question',
            type: 'final',
            questions: [
              {
                question: 'Did you perceive any degree of bias in any part of the program? (If yes, please describe in box below.)',
                options: [
                  { question: 'Yes', value: false },
                  { question: 'No', value: false },
                ],
              },
            ],
            otherTextPlaceholder: 'Comment on this information or this questionnaire',
            otherTextValue: null,
          },
        ],
        Fr: [
          {
            question: 'Quel impact a cette information sur vous ou sur votre pratique? Cochez tout ce qui s’applique à la situation.',
            options: [
              { question: 'J’ai appris quelque chose de nouveau', value: false },
              { question: 'Je suis motivé(e) à apprendre davantage', value: false },
              { question: 'Cela a confirmé que j’ai fait (je fais) ce qu’il fallait (faut)', value: false },
              { question: 'Je suis rassuré(e)', value: false },
              { question: 'Cela me rappelle quelque chose que je savais déjà', value: false },
              { question: 'Je ne suis pas satisfait(e)', value: false },
              {
                question: 'Il y a un problème avec la manière dont cette information est présentée',
                value: false,
                subQuestions: {
                  question: 'Si oui, quel est le problème rencontré?',
                  options: [
                    { question: 'Il y a trop d’information', value: false },
                    { question: "Il n'y a pas assez d'information", value: false },
                    { question: 'L’information est mal rédigée', value: false },
                    { question: 'C’est trop technique', value: false },
                    { question: 'Autre', value: false },
                  ],
                  otherTextValue: null,
                },
              },
              { question: 'Je ne suis pas d’accord avec le contenu de l’information', value: false },
              {
                question: 'Cette information peut être dommageable',
                value: false,
                subQuestions: {
                  question: 'Veuillez décrire la façon dont cette information peut être dommageable',
                  options: [{ question: 'Other', value: true, hidden: true }],
                  otherTextValue: null,
                },
              },
            ],
            type: 'multiselect',
          },
          // {
          //   question: 'Il y a un problème avec la manière dont cette information est présentée',
          //   type: 'single',
          //   options: [
          //     { question: 'Oui', value: false },
          //     { question: 'Non', value: false },
          //   ],
          //   subQuestions: [
          //     {
          //       question: 'Oui',
          //       options: [
          //         { question: 'Il y a trop d’information', value: false },
          //         { question: "Il n'y a pas assez d'information", value: false },
          //         { question: 'L’information est mal rédigée', value: false },
          //         { question: 'C’est trop technique', value: false },
          //         { question: 'Autre', value: false },
          //       ],
          //       otherTextValue: null,
          //     },
          //   ],
          // },
          {
            question: 'Est-ce que cette information est pertinente pour au moins un de vos patients?',
            type: 'single',
            options: [
              { question: 'Tout à fait pertinente', value: false },
              { question: 'Partiellement pertinente', value: false },
              { question: 'Non pertinente', value: false },
            ],
            nextQuestion: [
              {
                answer: 'Tout à fait pertinente',
                next: 'Utiliserez-vous cette information pour un patient en particulier?',
              },
              {
                answer: 'Partiellement pertinente',
                next: 'Utiliserez-vous cette information pour un patient en particulier?',
              },
              {
                answer: 'Non pertinente',
                next: 'Final Question',
              },
            ],
          },
          {
            question: 'Utiliserez-vous cette information pour un patient en particulier?',
            type: 'single',
            options: [
              { question: 'Oui', value: false },
              { question: 'Peut-être', value: false },
              { question: 'Non', value: false },
            ],
            subQuestions: [
              {
                question: 'Oui',
                options: [
                  { question: 'En raison de cette information, je modifierai la manière dont j’interviens avec ce patient', value: false },
                  { question: "J'avais plusieurs options pour ce patient, et j’utiliserai cette information pour justifier un choix", value: false },
                  { question: "Je pensais que je savais quoi faire, et j'ai utilisé cette information pour être plus sûr de la manière dont j’interviens avec ce patient", value: false },
                  { question: "J'ai utilisé cette information pour mieux comprendre un des problèmes de ce patient", value: false },
                  { question: "J’utiliserai cette information dans une discussion avec le patient, ou avec d'autres professionnels de la santé au sujet de ce patient", value: false },
                  { question: 'J’utiliserai cette information pour persuader le patient, ou persuader d’autres professionnels de santé, de modifier la manière dont on intervient avec ce patient', value: false },
                ],
                otherTextValue: null,
              },
            ],
            nextQuestion: [
              {
                answer: 'Oui',
                next: 'Pour ce patient, prévoyez-vous des bénéfices en ce qui a trait à la santé en raison de cette information?',
              },
              {
                answer: 'Peut-être',
                next: 'Final Question',
              },
              {
                answer: 'Non',
                next: 'Final Question',
              },
            ],
          },
          {
            question: 'Pour ce patient, prévoyez-vous des bénéfices en ce qui a trait à la santé en raison de cette information?',
            type: 'single',
            options: [
              { question: 'Oui', value: false },
              { question: 'Non', value: false },
            ],
            subQuestions: [
              {
                question: 'Oui',
                options: [
                  { question: "Cette information contribuera à améliorer l'état de santé de ce patient, son fonctionnement ou sa résilience (capacité à s'adapter aux facteurs de stress importants de la vie)", value: false },
                  { question: "Cette information contribuera à prévenir une maladie ou l'aggravation d’une maladie pour ce patient", value: false },
                  { question: 'Cette information contribuera à éviter un traitement, une procédure diagnostique, une intervention préventive ou une orientation (par ex., vers un autre spécialiste) inutile ou inapproprié', value: false },
                ],
                otherTextValue: null,
              },
            ],
          },
          {
            question: 'Final Question',
            type: 'final',
            questions: [
              {
                question: 'Avez-vous perçu un quelconque degré de parti pris dans une partie du programme? (Si oui, veuillez décrire.)',
                options: [
                  { question: 'Oui', value: false },
                  { question: 'Non', value: false },
                ],
              },
            ],
            otherTextPlaceholder: 'Commentez cette information ou ce questionnaire',
            otherTextValue: null,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'selectedPoem']),
    selectedQuestion() {
      return this.questions[this.selectedLanguage][this.questionIndex];
    },
    selectedSubQuestion() {
      return this.questions[this.selectedLanguage][this.questionIndex]?.subQuestions?.find((subQuestion) => subQuestion.question === this.questions[this.selectedLanguage][this.questionIndex]?.options?.find((option) => option.value)?.question);
    },
  },
  methods: {
    ...mapActions(['createCmeCredit']),
    ...mapMutations(['setSelectedPoem']),
    openDialog() {
      this.displayDialog = true;
    },
    closeDialog() {
      this.displayDialog = false;
    },
    submitCreateCmeCredit() {
      this.isLoading = true;
      let data = [];
      this.questions[this.selectedLanguage]
        .filter((question) => {
          return ['multiselect', 'single'].includes(question.type);
        })
        .forEach((question) => {
          let result = {
            question: question.question,
            options: question.options,
            subQuestions: question?.subQuestions,
          };
          data.push(result);
        });

      this.questions[this.selectedLanguage]
        .filter((question) => {
          return ['final'].includes(question.type);
        })
        .forEach((question) => {
          let result = {
            question: question.question,
            options: question.questions,
            otherTextValue: question.otherTextValue,
          };
          data.push(result);
        });

      this.createCmeCredit({
        feedback_form: JSON.stringify(data),
        user_id: this.loggedInUser.id,
        creditable_id: this.selectedPoem.id,
        creditable_type: 'App\\Models\\Poem',
      })
        .then(() => {
          this.setSelectedPoem({ ...this.selectedPoem, cme_credits: [{ credit: true }] });
          this.isLoading = false;
          this.lectureCreditFormSubmitted = true;
          this.$toast.add({
            severity: 'success',
            summary: 'Feedback Submitted',
            detail: 'You can now download the certificate for your credit(s) from My Credits section.',
            life: 5000,
          });
          this.mixpanel.track('CME - Submitted Poem Cme Claim Credit');
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    validateQuestionFilledOut() {
      this.errors = {};
      if (this.selectedQuestion.options) {
        const selectedOption = this.selectedQuestion.options.find((option) => option.value);

        if (!selectedOption) {
          this.errors = {
            question: this.selectedLanguage === 'En' ? 'Please select at least one' : 'Veuillez en sélectionner au moins un',
          };

          return false;
        }

        if (this.selectedQuestion.subQuestions && this.selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedOption.question)) {
          const selectedSubQuestion = this.selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedOption.question);

          if (selectedSubQuestion.options.length > 0) {
            const selectedSubQuestionOption = selectedSubQuestion.options.find((option) => option.value);

            if (!selectedSubQuestionOption) {
              this.errors = {
                subQuestion: this.selectedLanguage === 'En' ? 'Please select at least one' : 'Veuillez en sélectionner au moins un',
              };

              return false;
            }

            const selectedSubQuestionOther = selectedSubQuestion.options.find((option) => option.question === 'Other' && option.value);

            if (selectedSubQuestionOther && !selectedSubQuestion.otherTextValue) {
              this.errors = {
                otherTextValue: this.selectedLanguage === 'En' ? 'Please describe below' : 'Veuillez décrire ci-dessous',
              };
              return false;
            }
          } else if (!selectedSubQuestion.otherTextValue) {
            this.errors = {
              otherTextValue: this.selectedLanguage === 'En' ? 'Please describe below' : 'Veuillez décrire ci-dessous',
            };
            return false;
          }
        }
        const selectedOptions = this.selectedQuestion.options.filter((option) => option.value);
        const optionsWithSubQuestions = selectedOptions.filter((o) => o.subQuestions);
        let hasSubSubQuestionError = false;
        optionsWithSubQuestions.forEach((option) => {
          let otherProblem = false;
          const selectedSubQuestionOption = option.subQuestions?.options?.find((option) => option.value);
          if (!selectedSubQuestionOption) {
            this.errors = {
              subQuestion: this.selectedLanguage === 'En' ? 'Please select at least one' : 'Veuillez en sélectionner au moins un',
            };
            hasSubSubQuestionError = true;
          }
          option.subQuestions?.options?.forEach((opt) => {
            if ((opt.question == 'Other' || opt.question == 'Autre') && opt.value && !option.subQuestions.otherTextValue) {
              otherProblem = true;
            }
          });
          if (otherProblem) {
            this.errors = {
              otherTextValue: this.selectedLanguage === 'En' ? 'Please describe below' : 'Veuillez décrire ci-dessous',
            };
            hasSubSubQuestionError = true;
          }
        });
        if (hasSubSubQuestionError) {
          return false;
        }
      } else if (this.selectedQuestion.questions) {
        let allQuestionOptionsSelected = true;
        for (let i = 0; i < this.selectedQuestion.questions.length; i++) {
          const selectedOption = this.selectedQuestion.questions[i].options.find((option) => option.value);
          allQuestionOptionsSelected = allQuestionOptionsSelected && !!selectedOption;
          if (!selectedOption) {
            this.errors[`question-${i}`] = this.selectedLanguage === 'En' ? 'Please select at least one' : 'Veuillez en sélectionner au moins un';
          }
        }

        return allQuestionOptionsSelected;
      }

      return true;
    },
    goToNext() {
      if (!this.validateQuestionFilledOut()) {
        return;
      }
      this.questionIndexProcessStack.push(this.questionIndex);
      if (this.selectedQuestion.nextQuestion) {
        const answer = this.selectedQuestion.options.find((option) => option.value);
        const next = this.selectedQuestion.nextQuestion.find((n) => answer.question === n.answer);
        const nextIndex = this.questions[this.selectedLanguage].findIndex((question) => question.question === next.next);
        this.questionIndex = nextIndex;
      } else if (this.questions[this.selectedLanguage].length - 1 === this.questionIndex) {
        this.submitCreateCmeCredit();
      } else {
        this.questionIndex++;
      }
    },
    goBack() {
      this.errors = {};
      this.selectedQuestion?.options?.forEach((option) => {
        option.value = false;
      });

      this.selectedQuestion?.questions?.forEach((question) => {
        question.options.forEach((option) => {
          option.value = false;
        });
      });
      this.selectedQuestion?.subQuestions?.forEach((subQuestion) => {
        subQuestion.options.forEach((option) => {
          option.value = false;
        });
        subQuestion.otherTextValue = null;
      });

      if (this.selectedQuestion.otherTextValue) {
        this.selectedQuestion.otherTextValue = null;
      }

      this.questionIndex = this.questionIndexProcessStack.pop();
    },
    yesNoOnClick(selection) {
      this.selectedQuestion?.options?.forEach((option) => {
        option.value = selection.question === option.question;
      });

      if (this.selectedQuestion?.subQuestions) {
        const subQuestions = this.selectedQuestion.subQuestions;
        const selectedSubQuestion = subQuestions.find((subQuestion) => subQuestion.question === selection.question);
        if (!selectedSubQuestion) {
          this.selectedQuestion.subQuestions.forEach((subQuestion) => {
            subQuestion.options.forEach((option) => {
              option.value = false;
            });
            subQuestion.otherTextValue = null;
          });
          this.goToNext();
        }
      } else {
        this.goToNext();
      }
    },
    yesNoFinalOnClick(selectedQuestion, selectedOption) {
      const question = this.selectedQuestion.questions.find((question) => {
        return question.question === selectedQuestion.question;
      });

      question?.options?.forEach((option) => {
        option.value = selectedOption.question === option.question;
      });
    },
    onSelectedLanguageChange() {
      this.questionIndex = 0;
      this.questionIndexProcessStack = [];
    },
    subSubQuestionChanged(option) {
      if (!option.value && option.subQuestions) {
        if (option.subQuestions?.otherTextValue) {
          option.subQuestions.otherTextValue = null;
        }
        option.subQuestions?.options.forEach((opt) => {
          if (!opt.hidden) {
            opt.value = false;
          }
        });
      }
    },
  },
  created() {
    if (this.getUserProvinceName(this.loggedInUser) == 'Quebec') {
      this.questions.En.forEach((question) => {
        if (question.question == 'Final Question') {
          question.questions.push({
            question: 'Did the activity respect the CQDPCM Code of Ethics (http://cqdpcm.ca/)?',
            options: [
              { question: 'Yes', value: false },
              { question: 'No', value: false },
            ],
          });
        }
      });
      this.questions.Fr.forEach((question) => {
        if (question.question == 'Final Question') {
          question.questions.push({
            question: "L'activité a-t-elle respecté le Code de déontologie du CQDPCM (http://cqdpcm.ca/)",
            options: [
              { question: 'Oui', value: false },
              { question: 'Non', value: false },
            ],
          });
        }
      });
    }
  },
  unmounted() {},
};
</script>

<style></style>

<template>
  <div @click="logFeedClick(post)" class="w-full p-3 mb-2 text-sm border border-solid rounded-xl border-1" v-for="(post, index) in posts" :key="index">
    <div class="flex flex-row justify-between mb-1">
      <span class="text-xs font-bold">{{ post.title.rendered }}</span>
      <!-- <span class="text-gray-400 post-date">{{ getPostDate(post.date) }}</span> -->
    </div>
    <div class="post-content" v-html="post.content.rendered"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      postsUrl: 'https://virtualhallway.ca/wp-json/wp/v2/whats-new/?_embed&per_page=3&author=',
      queryOptions: {
        per_page: 10,
        page: 1,
      },
      posts: [],
    };
  },
  computed: {
    ...mapGetters(['actingUser']),
  },

  methods: {
    ...mapActions(['logFeed']),
    logFeedClick(post) {
      this.logFeed({ user_id: this.actingUser.id, user: this.actingUser, post: post.slug })
        .then(() => {})
        .catch(() => {});
    },
    getRecentMessages() {
      axios
        .get(this.postsUrl, { params: this.queryOptions })
        .then((response) => {
          this.posts = response.data;
        })
        .catch(() => {
          // this.showGenericErrorToast();
        });
    },
    getPostDate(date) {
      return moment(date).format('MMM');
    },
  },
  mounted() {
    this.getRecentMessages();
  },
};
</script>

<style>
.post-date {
  font-size: 0.6rem;
  line-height: 1;
}

.post-content {
  font-size: 0.75rem;
  line-height: 1.2;
}

.post-content a {
  color: var(--vh-blue);
  font-weight: 600;
}
.post-content a:hover {
  color: #0069d9;
}
</style>

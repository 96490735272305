import moment from 'moment';
import globals from '../../globals';

const state = {
  activeTour: null,
  tourSpecialist: {
    first_name: 'Alexander',
    last_name: 'Thomas Augusta',
    profile: {
      // slice off the api/ globals mainApiUrl
      profile_picture_location: globals.APIs.mainApiUrl().slice(0, -4) + 'storage/avatars/Dr. Alexander Thomas Augusta.png',
    },
    practice_province: {
      name: 'Tour',
    },
    specialist_preference: {
      call_asap_limit: 5,
      call_asap_optin: true,
      call_asap_turnaround_time_in_days: 14,
      econsult_limit: 10,
      econsult_optin: false,
      referral_duration_in_minutes: 10,
      referral_time_range_in_hours: 1,
      smart_path_optin: 0,
    },
    expertise_areas: [
      {
        id: 1,
        name: 'Surgery',
        specialty_id: 1,
      },
    ],
    phoneConsult: true,
    onDemandConsult: true,
    eConsult: false,
    nextAvailabilityTimes: {
      start_datetime: moment().add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: moment().add(4, 'hours').add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
      status: 'active',
    },
  },
  tourSpecialty: {
    id: 0,
    name: 'Tour Specialty',
    practice_province: { name: 'Tour' },
    specialty_status: 'TOUR',
  },
};

const getters = {
  activeTour: (state) => {
    return state.activeTour;
  },
  tourSpecialist: (state) => {
    return state.tourSpecialist;
  },
  tourSpecialty: (state) => {
    return state.tourSpecialty;
  },
};

const actions = {};

const mutations = {
  setActiveTour(state, data) {
    state.activeTour = data;
  },
  updateActiveTourStep(state, data) {
    if (state.activeTour) {
      state.activeTour.step = data;
    }
  },
  updateActiveTourGrayOverlay(state, data) {
    if (state.activeTour) {
      state.activeTour.grayOverlay = data;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import store from '@/store';

export class TourSteps {
  constructor(router) {
    this.router = router;
    this.steps = {
      BOOK_PHONE_CONSULT: [
        {
          target: '#book-a-consult-tour',
          content: '<h1 class="!mb-4">Book a consult.</h1><p>This is where you go if you want to book a consult with a physician to discuss a patient case.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              store.commit('updateActiveTourStep', null);
              this.toggleSideBar(true);
              this.router.push('/makereferral');
              resolve('success');
            }),
        },
        {
          target: '#tour-teleport-target',
          content: '<h1 class="!mb-4">Select a specialty.</h1><p>Here you select which specialty you want to consult with.</p>',
          params: {
            enableScrolling: false,
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.toggleSideBar(false);
              store.commit('updateActiveTourStep', 'tour_select_specialty_0');
              resolve('success');
            }),
        },
        {
          target: '#tour-teleport-target',
          content: '<h1 class="!mb-4">Select a specialist.</h1><p>You can view different specialists from within a single specialty and select the one that you wish to consult with.</p>',
          params: {
            enableScrolling: false,
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.toggleSideBar(false); // need this here again, for the case that select specialty step is skipped
              store.commit('updateActiveTourStep', 'tour_select_specialist');
              resolve('success');
            }),
        },
        {
          target: '#phoneConsultButton',
          content: "<h1 class='!mb-4'>Pick an encounter method.</h1><p>There are different ways you can get help on your patient case. Most commonly, you can schedule a phone call, or if you simply want a call back as soon as possible, you can select on demand.</p>",
          before: (type) =>
            new Promise((resolve, reject) => {
              store.commit('updateActiveTourStep', 'openSpecialistModal');
              resolve('success');
            }),
        },
      ],
      SET_AVAILABILITY: [
        {
          target: '#availability',
          content: '<h1 class="!mb-4">Set your availability.</h1><p>You can set your availability in the calendar here, so clinicians can book consults with you.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.toggleSideBar(true);
              this.router.push('/schedules');
              resolve('success');
            }),
        },
        {
          target: '#tour-teleport-target',
          content: '<h1 class="!mb-4">Set your schedule.</h1><p>You can set a recurring weekly schedule, or you can set it by selecting individual dates and times.</p>',
          params: {
            placement: 'top',
            enableScrolling: false,
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              store.commit('updateActiveTourStep', 'tour_set_availability');
              this.toggleSideBar(false);
              resolve('success');
            }),
        },
      ],
      VIEW_REFERRALS_TABLE: [
        {
          target: '#my-consults-tour',
          content: '<h1 class="!mb-4">View your consults.</h1><p>This is where you go if you want to view all of your past and upcoming consults.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.router.push('/referralhistory');
              store.commit('updateActiveTourStep', 'referralHistorySideBar');
              this.toggleSideBar(true);
              resolve('success');
            }),
        },
        {
          target: '#tour-teleport-target',
          content: '<h1 class="!mb-4">Your consults.</h1><p>All of your consults are in this table. You can view them and export them from this page.</p>',
          params: {
            placement: 'top',
            enableScrolling: false,
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              store.commit('updateActiveTourStep', 'referralHistoryList');
              this.toggleSideBar(false);
              resolve('success');
            }),
        },
      ],
      VIEW_CME_LECTURES: [
        {
          target: '#lectures',
          content: '<h1 class="!mb-4">Continuing medical education.</h1><p>Click here to watch lectures on a wide range of clinical topics. Most of these talks are accredited, so you can claim credits from within the app with the click of a button.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.router.push('/lectures');
              this.toggleSideBar(true);
              resolve('success');
            }),
        },
        {
          target: '#tour-teleport-target',
          content: '<h1 class="!mb-4">Lectures.</h1><p>Register for upcoming live lectures or watch past lectures. After you watch them, click Claim Credit to get your continuing education credit.</p>',
          params: {
            placement: 'top',
            enableScrolling: false,
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              store.commit('updateActiveTourStep', 'tour_lectures');
              this.toggleSideBar(false);
              resolve('success');
            }),
        },
      ],
      VIEW_CME_POEMS: [],
      VIEW_RESOURCES: [
        {
          target: '#resources',
          content: '<h1 class="!mb-4">Resources.</h1><p>In the Resources tab you can read papers and clinical tools that were uploaded by your colleagues.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.router.push('/resources');
              this.toggleSideBar(true);
              resolve('success');
            }),
        },
        // {
        //   target: '#tour-teleport-target',
        //   content: '<h1 class="!mb-4">Resources.</h1><p>Choose from many uploaded resources across a range of specialties.</p>',
        //   params: {
        //     placement: 'right',
        //     enableScrolling: false,
        //   },
        //   before: (type) =>
        //     new Promise((resolve, reject) => {
        //       store.commit('updateActiveTourStep', 'tour_featured_file_0');
        //       this.toggleSideBar(false);
        //       resolve('success');
        //     }),
        // },
      ],
      CREATE_NOTES: [
        {
          target: '#notes',
          content: '<h1 class="!mb-4">Notes.</h1><p>Think of this as your notepad where you can write clinical notes on the go. Instead of jotting a brief encounter on a sticky note, you can write it in Notes where it is secure and won\'t get lost.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.router.push('/notes');
              this.toggleSideBar(true);
              resolve('success');
            }),
        },
        // {
        //   target: '#tour-teleport-target',
        //   content: '<h1 class="!mb-4">Notes.</h1><p>Create notes </p>',
        //   params: {
        //     placement: 'top',
        //     enableScrolling: false,
        //   },
        //   before: (type) =>
        //     new Promise((resolve, reject) => {
        //       store.commit('updateActiveTourStep', 'add-new-note');
        //       this.toggleSideBar(false);
        //       resolve('success');
        //     }),
        // },
      ],
      VIEW_BILLING: [
        {
          target: '#billing',
          content: '<h1 class="!mb-4">Billing.</h1><p>If you are fee for service, you can bill for your encounters. Set up billing in this section.</p>',
          params: {
            placement: 'right',
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.router.push('/billing');
              this.toggleSideBar(true);
              resolve('success');
            }),
        },
      ],
    };
  }
  toggleSideBar(pop) {
    let sidebar = document.getElementById('theSideBarMainContainer');
    store.commit('setShowTheSideBar', pop);
    if (pop) {
      sidebar.style.zIndex = 1001;
    } else {
      sidebar.style.zIndex = 1;
    }
  }
}
